<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="contentviewrp"
      :item_no="item_No"
    />

    <v-container fluid>
      <!-- New UI -->
      <v-card class="rounded-lg">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" xs="12" md="7">
              <div class="d-flex flex-row">
                <v-tabs
                  grow
                  class="tab-mobileres"
                  color="secondary"
                  direction="vertical"
                  show-arrows
                >
                  <v-tab
                    tag
                    class="text-capitalize tab-style"
                    style="font-size: 20px; width: 130px"
                    @click="
                      $store.commit('changeTab', 'tab-1'),
                        (isSelect = false),
                        (viewstartdate = new Date()
                          .toISOString()
                          .substr(0, 10)),
                        (viewenddate = new Date().toISOString().substr(0, 10)),
                        (course = []),
                        (values = []),
                        (startdate = ''),
                        (enddate = '')
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-file-document</v-icon
                    >
                    {{ $t("article") }}
                  </v-tab>

                  <v-tab
                    class="text-capitalize tab-style"
                    style="font-size: 20px; width: 150px"
                    @click="
                      $store.commit('changeTab', 'tab-2'),
                        (isSelect = false),
                        (viewstartdate = new Date()
                          .toISOString()
                          .substr(0, 10)),
                        (viewenddate = new Date().toISOString().substr(0, 10)),
                        (course = []),
                        (values = []),
                        (startdate = ''),
                        (enddate = '')
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-volume-high</v-icon
                    >
                    {{ $t("sound") }}
                  </v-tab>

                  <v-tab
                    class="text-capitalize tab-style"
                    style="font-size: 20px; width: 130px"
                    @click="
                      $store.commit('changeTab', 'tab-3'),
                        (isSelect = false),
                        (viewstartdate = new Date()
                          .toISOString()
                          .substr(0, 10)),
                        (viewenddate = new Date().toISOString().substr(0, 10)),
                        (course = []),
                        (values = []),
                        (startdate = ''),
                        (enddate = '')
                    "
                  >
                    <v-icon style="font-size: 30px" class="mr-1"
                      >mdi-youtube-tv</v-icon
                    >
                    {{ $t("video") }}
                  </v-tab>

                  <v-tab
                    class="text-capitalize tab-style"
                    style="font-size: 20px"
                    @click="
                      $store.commit('changeTab', 'tab-4'),
                        (isSelect = false),
                        (viewstartdate = new Date()
                          .toISOString()
                          .substr(0, 10)),
                        (viewenddate = new Date().toISOString().substr(0, 10)),
                        (course = []),
                        (values = []),
                        (startdate = ''),
                        (enddate = '')
                    "
                  >
                    <v-img
                      v-if="this.$store.getters.tabs == 'tab-4'"
                      src="@/assets/images/ks_green.svg"
                      class="mr-1"
                      contain
                      width="20"
                    >
                    </v-img>
                    <v-img
                      v-else
                      src="@/assets/images/ks.svg"
                      class="mr-1"
                      contain
                      width="20"
                    ></v-img>
                    {{ $t("lessonlearn") }}
                  </v-tab>
                </v-tabs>
              </div>
            </v-col>
            <v-spacer></v-spacer>

            <v-col
              cols="12"
              xs="12"
              md="5"
              lg="5"
              class="d-flex justify-end align-center"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'"
            >
              <v-btn
                class="text-capitalize px-2"
                style="
                  color: #424242;
                  font-size: 16px;
                  border: 1px solid #c0c0c0;
                  box-shadow: unset !important;
                  border-radius: 8px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                "
                @click="showFilterTools = !showFilterTools"
              >
                <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
                {{ $t("filter") }}
              </v-btn>

              <v-btn
                outlined
                @click="exportExcel"
                style="
                  color: #a6cc39;
                  font-size: 16px;
                  border: 1px solid #c0c0c0;
                  box-shadow: unset !important;
                  border-radius: 8px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                "
                class="ml-3 text-capitalize px-2"
              >
                <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
                {{ $t("exportexcel") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider
            :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'"
          ></v-divider>

          <v-row v-if="showFilterTools" class="d-flex mt-3 px-2">
            <v-col cols="12" xs="12" sm="6" lg="2">
              <label
                for="createdateM"
                style="
                  font-size: 16px;
                  color: #424242;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("createdateM") }}</label
              >
              <v-menu
                v-model="frommenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    placeholder="yyyy/mm/dd"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startdate"
                  @input="frommenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" lg="2">
              <label
                for="to"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("to") }}
              </label>
              <v-menu
                v-model="tomenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="enddate"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    outlined
                    dense
                    placeholder="yyyy/mm/dd"
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="enddate"
                  :allowed-dates="allowedEndDates"
                  @input="tomenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" lg="3" class="pre-fix">
              <div v-if="this.$store.getters.tabs != 'tab-4'">
                <label
                  for="category"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("category") }}
                </label>
                <v-select
                  v-model="course"
                  :items="Categoryitems"
                  hide-no-data
                  hide-details
                  item-text="topicName"
                  item-value="id"
                  solo
                  @change="(event) => SelectCategory(event)"
                  @blur="clearSearchWord(searchtype)"
                  dense
                  placeholder="-- Please select --"
                  clearable
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchcategory"
                          :placeholder="$t('search')"
                          @input="searchInLists(), (searchtype = 'category')"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item ripple @click="getSelectAll">
                      <v-list-item-action>
                        <v-icon :color="course.length > 0 ? '#a6cc39' : ''">{{
                          icon
                        }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.topicName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ course.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              lg="5"
              class="d-flex align-end justify-end"
            >
              <v-text-field
                outlined
                dense
                clearable
                style="border-radius: 8px"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'max-width: unset'
                    : 'max-width: 250px'
                "
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('search')"
                v-model="search"
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="showFilterTools" class="d-flex px-2">
            <v-col cols="12" xs="12" sm="6" lg="2">
              <label
                for="viewdatefrom"
                style="
                  font-size: 16px;
                  color: #424242;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("viewdatefrom") }}</label
              >
              <v-menu
                v-model="viewfrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="viewstartdate"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="viewstartdate"
                  @change="
                    StartViewDate(), (viewfrom = false), (isSelect = true)
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" lg="2">
              <label
                for="to"
                style="
                  font-size: 16px;
                  color: #424242;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("to") }}</label
              >
              <v-menu
                v-model="viewto"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="viewenddate"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="viewenddate"
                  :allowed-dates="allowedviewEndDates"
                  @change="viewto = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" lg="3" class="pre-fix">
              <label
                for="title"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("title") }}</label
              >
              <v-select
                v-model="values"
                :items="Titleitems"
                hide-no-data
                placeholder="-- Please select --"
                hide-details
                solodense
                item-text="title"
                item-value="id"
                dense
                solo
                @change="(event) => SelectTitle(event)"
                @blur="clearSearchWord(searchtype)"
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchtitle"
                        :placeholder="$t('search')"
                        @input="searchInLists(), (searchtype = 'title')"
                        autocomplete="off"
                        hide-details
                        class="searchinlist"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item ripple @click="SelectAllTitle">
                    <v-list-item-action>
                      <v-icon :color="values.length > 0 ? '#a6cc39' : ''">{{
                        Titleicon
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.title }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ values.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" lg="4" class="d-flex align-end">
              <v-btn
                text
                class="text-capitalize"
                height="40"
                style="
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                  color: #424242;
                  font-size: 16px;
                "
                @click="clearAll()"
                >{{ $t("clearall") }}</v-btn
              >
              <v-btn
                min-width="100"
                height="40"
                class="text-capitalize btn_hover_effect ml-5"
                style="
                  color: #ffff;
                  border: 1px solid #a6cc39;
                  border-radius: 8px;
                  font-size: 16px;
                "
                color="#A6CC39"
                @click="Search()"
                >{{ $t("search") }}</v-btn
              >
            </v-col>
          </v-row>

          <v-tabs-items v-model="tabs" class="mt-1">
            <v-tab-item value="tab-1">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
                item-key="titleID"
                :single-expand="true"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length - 2"></td>
                  <td :colspan="headers.length - 5" style="padding-right: 0">
                    <table class="table">
                      <caption></caption>
                      <tr>
                        <th></th>
                      </tr>
                      <tr v-for="(subItem, i) in item.articleViewlist" :key="i">
                        <td
                          :colspan="headers.viewTime"
                          class="text-left sub-content"
                        >
                          {{ subItem.username }}
                        </td>
                        <td :colspan="headers.detail" class="detail-column">
                          <v-tooltip
                            top
                            color="#424242"
                            v-model="subItem.subTooltip"
                          >
                            <template v-slot:activator="{ props }">
                              <v-icon
                                v-bind="props"
                                class="detail-icon"
                                color="#ECC407"
                                @click="Details(item, subItem)"
                                @mouseenter="subItem.subTooltip = true"
                                @mouseleave="subItem.subTooltip = false"
                                >mdi-alert-circle</v-icon
                              >
                            </template>
                            <span>Detail</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </table>
                  </td>
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="soundHeaders"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
                item-key="videoID"
                :single-expand="true"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length - 3"></td>
                  <td :colspan="headers.length - 4" style="padding-right: 0">
                    <table class="table">
                      <caption></caption>
                      <tr>
                        <th></th>
                      </tr>
                      <tr v-for="(subItem, i) in item.soundList" :key="i">
                        <td
                          :colspan="headers.length - 4"
                          class="text-left sub-content"
                        >
                          <div class="file-name">
                            {{ subItem.soundName }}
                          </div>

                          <table class="table">
                            <caption></caption>
                            <tr>
                              <th></th>
                            </tr>
                            <tr
                              v-for="(lastItem, j) in subItem.soundViewList"
                              :key="j"
                            >
                              <td
                                :colspan="headers.fileName"
                                style="width: 200px"
                              ></td>
                              <td
                                :colspan="headers.viewTime"
                                class="text-left sub-content"
                              >
                                {{ lastItem.username }}
                              </td>
                              <td
                                :colspan="headers.detail"
                                class="detail-column-two"
                              >
                                <v-tooltip
                                  top
                                  color="#424242"
                                  v-model="subItem.subTooltip"
                                >
                                  <template v-slot:activator="{ props }">
                                    <v-icon
                                      v-bind="props"
                                      class="detail-icon"
                                      color="#ECC407"
                                      @click="
                                        MediaDetails(item, subItem, lastItem)
                                      "
                                      @mouseenter="subItem.subTooltip = true"
                                      @mouseleave="subItem.subTooltip = false"
                                      >mdi-alert-circle</v-icon
                                    >
                                  </template>
                                  <span>Detail</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="soundHeaders"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
                item-key="videoID"
                :single-expand="true"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length - 3"></td>
                  <td :colspan="headers.length - 4" style="padding-right: 0">
                    <table class="table">
                      <caption></caption>
                      <tr>
                        <th></th>
                      </tr>
                      <tr v-for="(subItem, i) in item.soundList" :key="i">
                        <td
                          :colspan="headers.length - 4"
                          class="text-left sub-content"
                        >
                          <div class="file-name">
                            {{ subItem.soundName }}
                          </div>

                          <table class="table">
                            <caption></caption>
                            <tr>
                              <th></th>
                            </tr>
                            <tr
                              v-for="(lastItem, j) in subItem.soundViewList"
                              :key="j"
                            >
                              <td
                                :colspan="headers.fileName"
                                style="width: 250px"
                              ></td>
                              <td
                                :colspan="headers.viewTime"
                                class="text-left sub-content"
                              >
                                {{ lastItem.username }}
                              </td>
                              <td
                                :colspan="headers.detail"
                                class="detail-column-two"
                              >
                                <v-tooltip
                                  top
                                  color="#424242"
                                  v-model="subItem.subTooltip"
                                >
                                  <template v-slot:activator="{ props }">
                                    <v-icon
                                      v-bind="props"
                                      class="detail-icon"
                                      color="#ECC407"
                                      @click="
                                        MediaDetails(item, subItem, lastItem)
                                      "
                                      @mouseenter="subItem.subTooltip = true"
                                      @mouseleave="subItem.subTooltip = false"
                                      >mdi-alert-circle</v-icon
                                    >
                                  </template>
                                  <span>Detail</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headersLL"
                :items="ArticleList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6 view-table"
                item-key="titleID"
                :single-expand="true"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length - 2"></td>
                  <td :colspan="headers.length - 4" style="padding-right: 0">
                    <table class="table">
                      <caption></caption>
                      <tr>
                        <th></th>
                      </tr>
                      <tr
                        v-for="(subItem, i) in item.lessonLearnViewList"
                        :key="i"
                      >
                        <td
                          :colspan="headers.viewTime"
                          class="text-left sub-content"
                        >
                          {{ subItem.username }}
                        </td>
                        <td :colspan="headers.detail" class="detail-column">
                          <v-tooltip
                            top
                            color="#424242"
                            v-model="subItem.subTooltip"
                          >
                            <template v-slot:activator="{ props }">
                              <v-icon
                                v-bind="props"
                                class="detail-icon"
                                color="#ECC407"
                                @click="Details(item, subItem)"
                                @mouseenter="subItem.subTooltip = true"
                                @mouseleave="subItem.subTooltip = false"
                                >mdi-alert-circle</v-icon
                              >
                            </template>
                            <span>Detail</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </table>
                  </td>
                </template>
              </v-data-table>
              <v-row justify="space-between">
                <v-col cols="auto" class="mr-auto">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>

      <v-dialog
        v-model="detaildialog"
        width="732"
        persistent
        :scrollable="true"
      >
        <v-card>
          <v-card-title style="font-size: 20px">
            <p class="dialog-p">
              <v-icon class="mb-1 mr-1">mdi-information-outline</v-icon
              >{{ $t("detail") }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="exportDetailExcel"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
            <v-btn
              icon
              @click="detaildialog = false"
              class="ml-3"
              color="#424242"
              style="
                border-radius: 8px !important;
                border: 1px solid rgba(66, 66, 66, 0.05);
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-5">
            <v-row class="ml-2">
              <v-col cols="6" class="pl-0">
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("title") }}:</label
                >
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 5.1rem;
                  "
                  >{{ detail.title }}</label
                >
              </v-col>
              <v-col cols="6" class="pl-0">
                <label
                  for="surname"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("surname") }}:</label
                >
                <label
                  for="name"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 55px;
                  "
                  >{{ detail.name }}</label
                >
              </v-col>
            </v-row>
            <v-row class="mt-1 ml-2">
              <v-col cols="6" class="pt-0 pl-0">
                <label
                  for="createdateM"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("createdateM") }}:</label
                >
                <label
                  for="createDateString"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 1.5rem;
                  "
                  >{{ detail.createDateString }}</label
                >
              </v-col>
            </v-row>
            <v-data-table
              :loading="detailloading"
              loading-text="Loading... Please wait"
              :headers="detailsheader"
              :items="DetailsList"
              class="mt-7 ml-2 mr-2 view-table"
              @page-count="detailpageCount = $event"
              :page.sync="detailpage"
              hide-default-footer
            >
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.status="{ item }">
                <v-chip
                  :class="
                    item.status == 'Finished'
                      ? 'finished'
                      : item.status == 'Not Finished'
                      ? 'notfinished'
                      : 'notstarted'
                  "
                  >{{ item.status }}</v-chip
                >
              </template>
            </v-data-table>
            <v-row justify="space-between">
              <v-col cols="auto" class="mr-auto">
                <div class="d-flex text-left">
                  <v-pagination
                    circle
                    v-model="detailpage"
                    class="pagination"
                    :length="detailpageCount"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="mediaDialog" width="732" persistent :scrollable="true">
        <v-card>
          <v-card-title style="font-size: 20px">
            <p class="dialog-p">
              <v-icon class="mb-1 mr-1">mdi-information-outline</v-icon
              >{{ $t("detail") }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="exportDetailExcel"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-1 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
            <v-btn
              icon
              @click="mediaDialog = false"
              class="ml-3"
              color="#424242"
              style="
                border-radius: 8px !important;
                border: 1px solid rgba(66, 66, 66, 0.05);
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-5">
            <v-row class="mt-2 ml-2">
              <v-col cols="6" class="pt-0 pl-0">
                <label
                  for="category"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("category") }}:</label
                >
                <label
                  for="category"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 25px;
                  "
                  >{{ detail.category }}</label
                >
              </v-col>
              <v-col cols="6" class="py-0 pl-0">
                <label
                  for="viewperiod"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("viewperiod") }}:</label
                >
                <label
                  for="periodDate"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 25px;
                  "
                  >{{ detail.periodDate }}</label
                >
              </v-col>
            </v-row>
            <v-row class="mt-2 ml-2">
              <v-col cols="6" class="pt-0 pl-0">
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("title") }}:</label
                >
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 61px;
                  "
                  >{{ detail.title }}</label
                >
              </v-col>
              <v-col cols="6" class="py-0 pl-0">
                <label
                  for="surname"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("surname") }}:</label
                >
                <label
                  for="name"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 55px;
                  "
                  >{{ detail.name }}</label
                >
              </v-col>
            </v-row>
            <v-row class="mt-2 ml-2">
              <v-col cols="6" class="pt-0 pl-0">
                <label
                  for="filenameL"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("filenameL") }}:</label
                >
                <label
                  for="file"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 20px;
                  "
                  >{{ detail.file }}</label
                >
              </v-col>
              <v-col cols="6" class="py-0 pl-0">
                <label
                  for="createdateM"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("createdateM") }}:</label
                >
                <label
                  for="createDateString"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 32px;
                  "
                  >{{ detail.createDateString }}</label
                >
              </v-col>
            </v-row>
            <v-data-table
              :loading="detailloading"
              loading-text="Loading... Please wait"
              :headers="detailsheader1"
              :items="DetailsList"
              class="mt-7 ml-2 mr-2 view-table"
              @page-count="detailpageCount = $event"
              :page.sync="detailpage"
              hide-default-footer
            >
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.status="{ item }">
                <v-chip
                  :class="
                    item.status == 'Finished'
                      ? 'finished'
                      : item.status == 'Not Finished'
                      ? 'notfinished'
                      : 'notstarted'
                  "
                  >{{ item.status }}</v-chip
                >
              </template>
            </v-data-table>
            <v-row justify="space-between">
              <v-col cols="auto" class="mr-auto">
                <div class="d-flex text-left">
                  <v-pagination
                    circle
                    v-model="detailpage"
                    class="pagination"
                    :length="detailpageCount"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
export default {
  data: () => ({
    item_No: 0,
    showFilterTools: false,
    searchtype: null,
    categoryCopy: [],
    titleCopy: [],
    searchcategory: null,
    searchtitle: null,
    searchtitle1: null,
    tabValue: "tab-1",
    frommenu: false,
    tomenu: false,
    viewfrom: false,
    viewto: false,
    isSelect: false,
    startdate: "",
    enddate: "",
    values: [],
    course: [],
    Categoryitems: [],
    Titleitems: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    detailpage: 1,
    detailpageCount: 0,
    detailloading: false,
    search: null,
    viewstartdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    viewenddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    detaildialog: false,
    mediaDialog: false,
    dateoffset: new Date().getTimezoneOffset(),
    loading: false,
    tempTitle: [],
    detail: {
      category: "",
      title: "",
      name: "",
      file: "",
      time: "",
    },
    breadcrumbs: [
      {
        sidebar_tilte: "report", //home
      },
      {
        text: "contentviewrp",
      },
    ],
    ArticleList: [],
    DetailsList: [],
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
  }),
  computed: {
    tabs: {
      get() {
        if (this.isSelect === false) {
          if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundTitle();
            this.GetSoundViewReport(1);
          } else if (this.$store.getters.tabs == "tab-3") {
            this.GetVideoTitle();
            this.GetVideoViewReport(1);
          } else if (this.$store.getters.tabs == "tab-4") {
            this.GetLessonLearnTitle();
            this.GetLLViewReport(1);
          } else {
            this.GetArticleTitle();
            this.GetArticleViewReport(1);
          }
        }
        return this.$store.getters.tabs;
      },
      set() {
        this.$store.commit("changeTab", this.tabValue);
      },
    },
    headers() {
      return [
        {
          text: this.$t("category"),
          value: "topicName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("createdateM"),
          value: "createDateString",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
        },
        // {
        //   text: this.$t("surname"),
        //   value: "username",
        //   align: "left",
        // },
        {
          text: this.$t("views"),
          value: "viewTime",
          align: "left",
          width: "220px",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "left",
        },
      ];
    },
    headersLL() {
      return [
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("createdateM"),
          value: "createDateString",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
        },
        // {
        //   text: this.$t("surname"),
        //   value: "username",
        //   align: "left",
        // },
        {
          text: this.$t("views"),
          value: "viewTime",
          align: "left",
          width: "220px",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "left",
        },
      ];
    },
    soundHeaders() {
      return [
        {
          text: this.$t("category"),
          value: "topicName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("createdateM"),
          value: "createDateString",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
          width: "250px",
        },
        {
          text: this.$t("filenameL"),
          value: "fileName",
          align: "left",
          width: "250px",
        },
        // {
        //   text: this.$t("surname"),
        //   value: "username",
        //   align: "left",
        // },
        {
          text: this.$t("views"),
          value: "viewTime",
          align: "left",
          width: "200px",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "left",
        },
      ];
    },
    selectAll() {
      return this.course.length === this.Categoryitems.length;
    },
    selectAllTitle() {
      return this.values.length === this.Titleitems.length;
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Titleicon() {
      if (this.selectAllTitle) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    detailsheader() {
      //for article & leasson learn tab
      return [
        {
          text: this.$t("viewdatetime"),
          value: "viewDateTime",
          align: "left",
        },
        {
          text: this.$t("status"),
          value: "status",
          align: "left",
        },
      ];
    },
    detailsheader1() {
      //for sound and video tabs
      return [
        {
          text: this.$t("viewdatetime"),
          value: "viewDateTime",
          align: "left",
        },
        {
          text: this.$t("ViewTime"),
          value: "viewTime",
          align: "left",
        },
        {
          text: this.$t("status"),
          value: "status",
          align: "left",
        },
      ];
    },
  },
  mounted() {
    this.$store.commit("changeTab", "tab-1");
    this.GetCategoryItems();
    this.GetArticleTitle();
    this.GetArticleViewReport(1);
  },
  methods: {
    clearAll() {
      this.values = [];
      this.course = [];
      this.startdate = "";
      this.viewstartdate = "";
      this.viewenddate = "";
      this.isSelect = false;
      switch (this.$store.getters.tabs) {
        case "tab-2":
          this.GetSoundViewReport(2);
          break;
        case "tab-3":
          this.GetVideoViewReport(2);
          break;
        case "tab-4":
          this.GetLLViewReport(2);
          break;
        default:
          this.GetArticleViewReport(2);
          break;
      }
      this.isSelect = true;
    },
    clearSearchWord(val) {
      switch (val) {
        case "category":
          this.searchcategory = null;
          break;
        case "title":
          this.searchtitle = null;
          break;
        case "title1":
          this.searchtitle1 = null;
          break;
        default:
          console.log("clear");
      }
      this.searchInLists();
    },

    searchInLists() {
      switch (this.searchtype) {
        case "category":
          if (!this.searchcategory) {
            this.Categoryitems = this.categoryCopy;
          }

          this.Categoryitems =
            this.searchcategory != null
              ? this.categoryCopy.filter((x) => {
                  return (
                    x.topicName
                      .toLowerCase()
                      .indexOf(this.searchcategory.toLowerCase()) > -1
                  );
                })
              : this.categoryCopy;
          break;
        case "title":
          if (!this.searchtitle) {
            this.Titleitems = this.titleCopy;
          }

          this.Titleitems =
            this.searchtitle != null
              ? this.titleCopy.filter((x) => {
                  return (
                    x.title
                      .toLowerCase()
                      .indexOf(this.searchtitle.toLowerCase()) > -1
                  );
                })
              : this.titleCopy;
          break;
        case "title1":
          if (!this.searchtitle1) {
            this.Titleitems = this.titleCopy;
          }

          this.Titleitems =
            this.searchtitle1 != null
              ? this.titleCopy.filter((x) => {
                  return (
                    x.title
                      .toLowerCase()
                      .indexOf(this.searchtitle1.toLowerCase()) > -1
                  );
                })
              : this.titleCopy;
          break;
        default:
          console.log("Searched...");
      }
    },

    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.startdate;
    },
    allowedviewDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedviewEndDates(val) {
      return val >= this.viewstartdate;
    },
    StartDate() {
      this.frommenu = false;
      if (this.enddate == "") {
        this.enddate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.startdate > this.enddate) {
        this.enddate = this.startdate;
      }
    },
    EndDate() {
      if (this.startdate == "") {
        this.startdate = this.enddate;
      }
    },
    StartViewDate() {
      if (this.viewstartdate > this.viewenddate) {
        this.viewenddate = this.viewstartdate;
      }
    },
    Details(item, subitem) {
      this.detaildialog = true;
      this.detail.title = item.titleName;
      this.detail.createDateString = item.createDateString;
      this.detail.name = subitem.username.replace(/\s*\(\d+\)/, "");
      this.detail.periodDate = item.periodDate;
      if (this.$store.getters.tabs == "tab-1") {
        this.GetArticleViewDetailReport(subitem);
        this.detail.category = item.topicName;
      } else {
        this.detail.category = null;
        this.GetLessonLearnViewDetailReport(subitem);
      }
    },
    MediaDetails(r_data, item, lastitem) {
      this.mediaDialog = true;
      this.detail.file = item.soundName;
      this.detail.name = lastitem.username.replace(/\s*\(\d+\)/, "");
      this.detail.title = r_data.titleName;
      this.detail.periodDate = r_data.periodDate;
      this.detail.createDateString = r_data.createDateString;
      this.detail.category = r_data.topicName;
      this.detail.time = item.time;
      if (this.$store.getters.tabs == "tab-2") {
        this.GetSoundViewDetailReport(lastitem, item.videoID);
      } else {
        this.GetVideoViewDetailReport(lastitem, item.videoID);
      }
    },
    getSelectAll() {
      if (this.course.length == 0) {
        this.course = this.Categoryitems.map(({ id }) => id);
      } else {
        this.course = [];
      }
    },
    SelectAllTitle() {
      if (this.values.length == 0) {
        this.values = this.Titleitems.map(({ id }) => id);
      } else {
        this.values = [];
      }
    },
    SelectCategory(event) {
      this.Titleitems = [];
      this.values = [];
      if (event.length > 0) {
        for (let i of event) {
          const found = this.tempTitle.filter((c) => c.topicID == i);
          if (found.length != 0) {
            if (this.Titleitems.length == 0) {
              this.Titleitems = found;
            } else {
              for (let title of found) {
                this.Titleitems.push(title);
              }
            }
          }
        }
      } else {
        this.Titleitems = this.tempTitle;
        if (this.values.length == 0) {
          if (this.$store.getters.tabs == "tab-1") {
            this.GetArticleViewReport(1);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundViewReport(1);
          } else if (this.$store.getters.tabs == "tab-4") {
            this.GetLLViewReport(1);
          } else {
            this.GetVideoViewReport(1);
          }
        } else {
          if (this.$store.getters.tabs == "tab-1") {
            this.GetArticleViewReport(2);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundViewReport(2);
          } else if (this.$store.getters.tabs == "tab-4") {
            this.GetLLViewReport(2);
          } else {
            this.GetVideoViewReport(2);
          }
        }
      }
    },
    SelectTitle(event) {
      if (event.length == 0) {
        if (this.course.length == 0)
          if (this.$store.getters.tabs == "tab-4") {
            this.GetLLViewReport(1);
          } else if (this.$store.getters.tabs == "tab-3") {
            this.GetVideoViewReport(1);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundViewReport(1);
          } else this.GetArticleViewReport(1);
        else {
          if (this.$store.getters.tabs == "tab-4") {
            this.GetLLViewReport(2);
          } else if (this.$store.getters.tabs == "tab-3") {
            this.GetVideoViewReport(2);
          } else if (this.$store.getters.tabs == "tab-2") {
            this.GetSoundViewReport(2);
          } else this.GetArticleViewReport(2);
        }
      }
    },
    Search() {
      this.isSelect = false;
      if (this.startdate == "" && this.enddate != "") {
        alert("Please select a start date to search for information");
      } else {
        switch (this.$store.getters.tabs) {
          case "tab-2":
            this.GetSoundViewReport(2);
            break;
          case "tab-3":
            this.GetVideoViewReport(2);
            break;
          case "tab-4":
            this.GetLLViewReport(2);
            break;
          default:
            this.GetArticleViewReport(2);
            break;
        }
      }
      this.isSelect = true;
    },
    async GetCategoryItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}Topic/GetTopicDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Categoryitems = temp.sort((a, b) =>
              a.topicName.toLowerCase() > b.topicName.toLowerCase() ? 1 : -1
            );
            that.categoryCopy = that.Categoryitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.Categoryitems.map((item, index) => ({
        ...item,
        no: ++index,
      }));
    },
    async GetSoundTitle() {
      let that = this;
      that.search = null;
      that.tempTitle = [];
      axios
        .get(
          `${that.web_url}Video/GetSoundDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetVideoTitle() {
      let that = this;
      that.search = null;
      that.Titleitems = [];
      that.tempTitle = [];
      axios
        .get(
          `${that.web_url}GuidedLesson/GetVideoDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetArticleTitle() {
      let that = this;
      that.search = null;
      that.tempTitle = [];
      axios
        .get(
          `${that.web_url}Contents/GetArticleDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetLessonLearnTitle() {
      let that = this;
      that.search = null;
      that.tempTitle = [];
      await axios
        .get(
          `${that.web_url}CaseStudies/GetLessonLearnDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
            }));
            that.Titleitems = temp.sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            );
            that.tempTitle = that.Titleitems;
            that.titleCopy = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },

    async GetArticleViewReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.course,
        titleID: page == 1 ? [] : this.values,
        createDateFrom: page == 1 ? "" : that.startdate,
        createDateTo: page == 1 ? "" : that.enddate,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}Reports/GetArticleViewReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
              articleViewlist: v.articleViewlist.map((sub) => ({
                ...sub,
                subTooltip: false,
              })),
            }));
          }
          that.item_No = that.ArticleList.length;
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetSoundViewReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.course,
        titleID: page == 1 ? [] : this.values,
        createDateFrom: page == 1 ? "" : that.startdate,
        createDateTo: page == 1 ? "" : that.enddate,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}Reports/GetSoundViewReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
              soundList: v.soundList.map((sub) => ({
                ...sub,
                soundViewList: sub.soundViewList.map((sub1) => ({
                  ...sub1,
                  subTooltip: false,
                })),
              })),
            }));
          }
          that.item_No = that.ArticleList.length;
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetVideoViewReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.course,
        titleID: page == 1 ? [] : this.values,
        createDateFrom: page == 1 ? "" : that.startdate,
        createDateTo: page == 1 ? "" : that.enddate,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}Reports/GetVideoViewReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
              soundList: v.soundList.map((sub) => ({
                ...sub,
                soundViewList: sub.soundViewList.map((sub1) => ({
                  ...sub1,
                  subTooltip: false,
                })),
              })),
            }));
          }
          that.item_No = that.ArticleList.length;
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetLLViewReport(page) {
      let that = this;
      that.ArticleList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        titleID: page == 1 ? [] : this.values,
        createDateFrom: page == 1 ? "" : that.startdate,
        createDateTo: page == 1 ? "" : that.enddate,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}Reports/GetLessonLearnViewReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ArticleList = response.data.data.map((v) => ({
              ...v,
              lessonLearnViewList: v.lessonLearnViewList.map((sub) => ({
                ...sub,
                subTooltip: false,
              })),
            }));
          }
          that.item_No = that.ArticleList.length;
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetArticleViewDetailReport(item) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      let request = {
        userID: item.userID,
        id: item.titleID,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_url}Reports/GetArticleViewDetailReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.DetailsList = response.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
              name: item.username,
              createDate: item.createDate,
              periodDate: item.periodDate,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item, index) => ({
        ...item,
        no: ++index,
      }));
    },
    async GetLessonLearnViewDetailReport(item) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      let request = {
        userID: item.userID,
        id: item.titleID,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_url}Reports/GetLessonLearnViewDetailReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.DetailsList = response.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
              name: item.username,
              createDate: item.createDate,
              periodDate: item.periodDate,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item, index) => ({
        ...item,
        no: ++index,
      }));
    },
    async GetSoundViewDetailReport(item, vdo_id) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      let request = {
        userID: item.userID,
        id: vdo_id,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_url}Reports/GetSoundViewDetailReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.DetailsList = response.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
              name: item.username,
              file: item.soundName,
              createDate: item.createDate,
              periodDate: item.periodDate,
              time: item.viewTime,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item, index) => ({
        ...item,
        no: ++index,
      }));
    },
    async GetVideoViewDetailReport(item, vdo_id) {
      let that = this;
      that.detailloading = true;
      that.DetailsList = [];
      let request = {
        userID: item.userID,
        id: vdo_id,
        viewDateFrom: that.viewstartdate,
        viewDateTo: that.viewenddate,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_url}Reports/GetVideoViewDetailReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.DetailsList = response.data.data.map((v) => ({
              ...v,
              courseName: item.topicName,
              title: item.titleName,
              name: item.username,
              file: item.soundName,
              createDate: item.createDate,
              periodDate: item.periodDate,
              time: item.viewTime,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.detailloading = false;
      return that.DetailsList.map((item, index) => ({
        ...item,
        no: ++index,
      }));
    },
    exportExcel() {
      let headers = [];
      let keys = [];
      let name = "";

      if (this.$store.getters.tabs == "tab-1") {
        name = "ContentViewing-Article";
        headers = ["Category", "Title", "Created date", "Show period", "View"];
        keys = [
          "topicName",
          "titleName",
          "createDate",
          "periodDate",
          "viewTime",
        ];
      } else if (
        this.$store.getters.tabs == "tab-2" ||
        this.$store.getters.tabs == "tab-3"
      ) {
        if (this.$store.getters.tabs == "tab-2") {
          name = "ContentViewing-Sound";
        } else {
          name = "ContentViewing-Video";
        }
        headers = [
          "Category",
          "Title",
          "Created date",
          "Show period",
          "File name",
          "View",
        ];
        keys = [
          "topicName",
          "titleName",
          "createDate",
          "periodDate",
          "soundName",
          "viewTime",
        ];
      } else {
        name = "ContentViewing-KnowledgeSharing";
        headers = ["Title", "Created date", "Show period", "View"];
        keys = ["titleName", "createDate", "periodDate", "viewTime"];
      }

      let fileName;
      const filterItems = this.ArticleList.map((x) => ({ ...x }));

      const data = [];
      data.push(headers);
      for (const item of filterItems) {
        let row = [];

        for (const key of keys) {
          row.push(item[key] || "");
        }
        if (this.$store.getters.tabs == "tab-1") {
          fileName = `${"ContentViewingReport-Article"}.xlsx`;
          row[headers.indexOf("Category")] = item.topicName;
          row[headers.indexOf("Title")] = item.titleName;
          row[headers.indexOf("Create Date")] = item.createDateString;
          row[headers.indexOf("Show Period")] = item.periodDate;
          row[headers.indexOf("View")] = item.viewTime;
          data.push(row);
          data.push(
            ...item.articleViewlist.map((x) => {
              return [
                " ", //item.category,
                " ", //item.title,
                " ", //item.createdate,
                " ", //item.view,
                x.username || " ",
              ];
            })
          );
        } else if (
          this.$store.getters.tabs == "tab-2" ||
          this.$store.getters.tabs == "tab-3"
        ) {
          if (this.$store.getters.tabs == "tab-2") {
            fileName = `${"ContentViewingReport-Sound"}.xlsx`;
          } else {
            fileName = `${"ContentViewingReport-Video"}.xlsx`;
          }

          row[headers.indexOf("Category")] = item.topicName;
          row[headers.indexOf("Title")] = item.titleName;
          row[headers.indexOf("Create Date")] = item.createDateString;
          row[headers.indexOf("Show Period")] = item.periodDate;
          row[headers.indexOf("File name")] = "";
          row[headers.indexOf("View")] = item.viewTime;
          data.push(row);

          item.soundList.forEach((x) => {
            x.soundViewList.forEach((y) => {
              data.push([
                item.category || " ",
                item.title || " ",
                item.createdat || " ",
                item.showperiod || " ",
                x.soundName || " ",
                y.username || " ",
              ]);
            });
          });
        } else {
          fileName = `${"ContentViewingReport-KnowledgeSharing"}.xlsx`;
          row[headers.indexOf("Category")] = item.topicName;
          row[headers.indexOf("Title")] = item.titleName;
          row[headers.indexOf("Create Date")] = item.createDateString;
          row[headers.indexOf("Show Period")] = item.periodDate;
          row[headers.indexOf("View")] = item.viewTime;
          data.push(row);
          data.push(
            ...item.lessonLearnViewList.map((x) => {
              return [
                " ", //item.category,
                " ", //item.title,
                " ", //item.createdate,
                x.username || " ",
              ];
            })
          );
        }
      }

      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet(name, {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: "true",
        };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: "true",
          };
        })
      );
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: "true",
        };
      });
      worksheet2.columns.forEach((column, i) => {
        if (i === 1) {
          column.width = 30;
          column.eachCell({ includeEmpty: true }, (cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        } else {
          worksheet2.columns.forEach((column, i) => {
            column.width = headers[i].length + (i <= 10 ? 20 : 30);
          });
        }
      });

      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
    async exportDetailExcel() {
      let headers = [];
      let keys = [];
      let name = "";
      if (
        this.$store.getters.tabs == "tab-1" ||
        this.$store.getters.tabs == "tab-4"
      ) {
        name =
          this.$store.getters.tabs == "tab-1"
            ? "Article-Detail"
            : "Knowledge Sharing-Detail";
        headers = ["View date - time", "Status"];
        keys = ["viewDateTime", "status"];
      } else {
        name =
          this.$store.getters.tabs == "tab-2" ? "Sound-Detail" : "Video-Detail";
        headers = ["View date - time", "View Time", "Status"];
        keys = ["viewDateTime", "viewTime", "status"];
      }
      const fileName = `${"ContentViewingReportDetail"}.xlsx`;
      const filterItems = this.DetailsList.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);
      for (const item of filterItems) {
        let row = [];

        for (const key of keys) {
          row.push(item[key] || "");
        }
        data.push(row);
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet(name, {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);
      if (
        this.$store.getters.tabs == "tab-1" ||
        this.$store.getters.tabs == "tab-4"
      ) {
        const labels = ["Title :", "Created date :", "Name - Surname :"];

        const values = [
          this.detail.title,
          this.detail.createDateString,
          this.detail.name,
        ];

        const labelColors = ["FF9900", "FF9900", "FF9900"];

        for (let i = 0; i < labels.length; i++) {
          const row = worksheet2.addRow([labels[i], values[i]]);
          const labelCell = row.getCell(1);

          labelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: labelColors[i] },
          };
        }
        worksheet2.addRow([]);
        data.forEach((element) => {
          worksheet2.addRow(element);
        });
        worksheet2.getRow(5).eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff9900" },
            bgColor: { argb: "ff9900" },
          };
          cell.font = {
            name: "Calibri",
            size: 11,
            color: { argb: "000000" },
            bold: true,
          };
          console.log(number);
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });

        worksheet2.eachRow((row, rowNumber) => {
          row.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            console.log(rowNumber);
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
        });
        for (let i = 1; i <= 3; i++) {
          const cell = worksheet2.getCell(i, 1);

          cell.alignment = {
            horizontal: "left",
            vertical: "middle",
            wrapText: true,
          };
        }
      } else if (
        this.$store.getters.tabs == "tab-2" ||
        this.$store.getters.tabs == "tab-3"
      ) {
        const labels = [
          "Category:",
          "Title :",
          "File Name",
          "Show Period",
          "Name - Surname :",
          "Created date :",
        ];

        const values = [
          this.detail.category,
          this.detail.title,
          this.detail.file,
          this.detail.periodDate,
          this.detail.name,
          this.detail.createDateString,
        ];

        const labelColors = [
          "FF9900",
          "FF9900",
          "FF9900",
          "FF9900",
          "FF9900",
          "FF9900",
        ];

        for (let i = 0; i < labels.length; i++) {
          const row = worksheet2.addRow([labels[i], values[i]]);
          const labelCell = row.getCell(1);

          labelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: labelColors[i] },
          };
        }
        worksheet2.addRow([]);
        data.forEach((element) => {
          worksheet2.addRow(element);
        });
        worksheet2.getRow(8).eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff9900" },
            bgColor: { argb: "ff9900" },
          };
          cell.font = {
            name: "Calibri",
            size: 11,
            color: { argb: "000000" },
            bold: true,
          };
          console.log(number);
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });

        worksheet2.eachRow((row, rowNumber) => {
          row.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            console.log(rowNumber);
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
        });
        for (let i = 1; i <= 6; i++) {
          const cell = worksheet2.getCell(i, 1);

          cell.alignment = {
            horizontal: "left",
            vertical: "middle",
            wrapText: true,
          };
        }
      }

      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        if (i === 1) {
          column.width = 30;
          column.eachCell({ includeEmpty: true }, (cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        } else {
          worksheet2.columns.forEach((column, i) => {
            column.width = headers[i].length + (i <= 8 ? 10 : 13);
          });
        }
      });

      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .pre-fix .v-input__slot {
  border: 1px solid #707070;
  font-size: 16px !important;
  border-radius: 4px !important;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  background: #f5f9e9;
}
::v-deep .input-border .v-input__slot {
  width: 200px !important;
}
::v-deep .searchtool .v-input__slot {
  width: 200px;
}
::v-deep .from-date .v-input__slot {
  width: 200px !important;
}
::v-deep .to-date .v-input__slot {
  width: 200px !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
::v-deep .v-list-item__content {
  color: #4d4f5c;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
}
::v-deep .btnStyle .v-btn__content {
  font-size: 20px;
  color: #fff;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 300px;
    overflow: auto;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 500px;
    overflow: auto;
  }
}
div#list-299 {
  width: 185px;
}
div#list-302 {
  width: 185px;
}
div#list-64 {
  width: 185px;
}
div#list-67 {
  width: 185px;
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
</style>

<style>
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.dialog-p {
  margin-bottom: 0px !important;
}
.view-table tr:nth-child(even) {
  background: unset;
}
.view-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.view-table thead th:last-child {
  border-radius: 0px 8px 8px 0px !important;
}
.finished {
  color: #4fb14e !important;
  background: rgba(111, 220, 66, 0.1) !important;
}
.notstarted {
  color: #ff6060 !important;
  background: rgba(255, 96, 96, 0.1) !important;
}
.notfinished {
  color: #424242 !important;
  background: rgba(66, 66, 66, 0.1) !important;
}
.before {
  color: #4390df !important;
  background: rgba(67, 144, 223, 0.1) !important;
}
.afterexpired {
  color: #ffb100 !important;
  background: rgba(255, 177, 0, 0.1) !important;
}
</style>

<style scoped>
::v-deep .table {
  table-layout: inherit; /**fixed */
  width: 100%;
}
::v-deep td.detail-column {
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
  padding-left: 10px;
}
::v-deep td.detail-column-two {
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
  padding-left: 0px;
}
::v-deep td.sub-content {
  width: 220px !important;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
  padding-right: 16px;
}
::v-deep .detail-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none !important;
}
::v-deep .file-name {
  width: 250px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  padding-top: 12px;
  padding-right: 10px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
  color: #424242;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #424242;
}

::v-deep .v-data-table__expand-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: 1px solid !important;
  border-radius: 50% !important;
  font-size: 20px;
  color: #424242;
}
</style>
